




































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ShipArrival from '@/models/ShipArrival.model';
import {namespace} from 'vuex-class';
import {shipArrivalStoreActions} from '@/store/shipArrival.store';
import {BookingState} from '@/enums/BookingState.enum';
import {Booking} from '@/models/Booking.model';
import {bookingStoreActions} from '@/store/booking.store';
import {slotStoreMutations} from '@/store/slot.store';
import Slot from '@/models/Slot.model';
import {UserRole} from '@/models/User.model';
import {SlotType} from '@/enums/SlotType.enum';

const ShipArrivalStore = namespace('shipArrival');
const BookingStore = namespace('booking');
const SlotStore = namespace('slot');

enum CONFIRM_MODE {
  DECLINE, ACCEPT
}

@Component({
  components: {
    SlotBookingsTableComponent: () => import(
        /* webpackChunkName: "SlotBookingsTableComponent" */
        '@/components/slotBookings/SlotBookingsTable.component.vue'),
  }
})
export default class ShipArrivalDetailsComponent extends Vue {

  @ShipArrivalStore.Action(shipArrivalStoreActions.GET)
  public loadShipArrivalDetails!: (payload: { id: string }) => Promise<ShipArrival>;

  @ShipArrivalStore.Action(shipArrivalStoreActions.SET_RELEVANT_CHANGES_FLAG)
  public updateRelevantChangesFlag!: (payload: { id: string, hasRelevantChanges: boolean }) => Promise<ShipArrival>;

  @ShipArrivalStore.Action(shipArrivalStoreActions.UPDATE_CALENDAR_SHIP_ARRIVAL)
  private updateCalendarShipArrivalAction!: () => Promise<ShipArrival>;

  @BookingStore.Action(bookingStoreActions.SET_STATUS)
  public setBookingStatusAction!: (payload: { booking: string, status: BookingState }) => Promise<Partial<Booking>>;

  @SlotStore.Mutation(slotStoreMutations.UPDATE_SLOTS_BOOKING)
  public updateSlotsBooking!: (payload: Partial<Booking>) => void;

  @SlotStore.Mutation(slotStoreMutations.UPDATE_RELEVANT_CHANGES)
  public updateRelevantChanges!: (payload: Partial<ShipArrival>) => void;

  @Prop({default: () => new ShipArrival()})
  public shipArrival!: ShipArrival;

  @Prop({default: false})
  public isCalenderView!: boolean;

  public shipArrivalDetails: ShipArrival | null = null;

  public showConfirmDialog: boolean = false;
  public confirmText: string = '';
  public confirmTitle: string = '';
  public confirmButtonColor: string = '';
  public confirmMode: CONFIRM_MODE = CONFIRM_MODE.ACCEPT;

  public isDataLoading: boolean = false;
  public isAcceptingSending: boolean = false;
  public isCancellationSending: boolean = false;
  public BookingState = BookingState;

  @Watch('shipArrival', {immediate: true})
  private async shipArrivalChanged() {
    await this.loadDetails();
  }

  public get bookedSlots(): Slot[] {
    if (this.shipArrivalDetails?.booking) {
      return this.shipArrivalDetails.booking.bookedSlots;
    } else {
      return [];
    }
  }

  public showConfirmDeclineDialog() {
    this.confirmMode = CONFIRM_MODE.DECLINE;
    this.confirmButtonColor = 'error';
    this.confirmTitle = this.$t('SHIP_ARRIVAL.DETAILS.DIALOG.DECLINE.TITLE').toString();
    this.confirmText = this.$t('SHIP_ARRIVAL.DETAILS.DIALOG.DECLINE.TEXT').toString();
    this.showConfirmDialog = true;
  }

  public showConfirmAcceptDialog() {
    this.confirmMode = CONFIRM_MODE.ACCEPT;
    this.confirmButtonColor = 'success';
    this.confirmTitle = this.$t('SHIP_ARRIVAL.DETAILS.DIALOG.ACCEPT.TITLE').toString();
    this.confirmText = this.$t('SHIP_ARRIVAL.DETAILS.DIALOG.ACCEPT.TEXT').toString();
    this.showConfirmDialog = true;
  }

  public confirmed() {
    this.showConfirmDialog = false;
    if (this.confirmMode === CONFIRM_MODE.ACCEPT) {
      this.acceptBooking();
    } else {
      this.declineBooking();
    }
  }

  public async loadDetails(reloadAll: boolean = false) {
    this.isDataLoading = true;
    this.shipArrivalDetails = await this.loadShipArrivalDetails({id: this.shipArrival!.id!});

    // Reset the flag that shows the relevant changes
    if (this.shipArrivalDetails.hasRelevantChanges &&
        this.$hasRole([UserRole.IT_AUTOMOBILE, UserRole.KEY_USER, UserRole.PLANER])) {
      await this.updateRelevantChangesFlag({id: this.shipArrival!.id!, hasRelevantChanges: false});
      this.shipArrivalDetails.hasRelevantChanges = false;

      if (this.isCalenderView) {
        this.updateRelevantChanges(this.shipArrivalDetails);
      }
    }

    this.isDataLoading = false;
    if (reloadAll) {
      this.$emit('reloadAll');
    }
  }

  public async acceptBooking() {
    try {
      this.isAcceptingSending = true;
      const partialBooking = await this.setBookingStatusAction({
        booking: (this.shipArrivalDetails!.booking as Booking).id!,
        status: BookingState.ACCEPTED
      });
      if (this.isCalenderView) {
        this.updateSlotsBooking({...partialBooking, shipArrival: this.shipArrivalDetails!.id!});
        await this.updateCalendarShipArrivalAction();
      }

      this.$emit('closeDialog', true);
    } catch (e) {
      console.log(e);
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } finally {
      this.isAcceptingSending = false;
    }
  }

  public async declineBooking() {
    try {
      this.isCancellationSending = true;
      const partialBooking = await this.setBookingStatusAction({
        booking: (this.shipArrivalDetails!.booking as Booking).id!,
        status: BookingState.DECLINED
      })
      if (this.isCalenderView) {
        this.updateSlotsBooking({...partialBooking, shipArrival: this.shipArrivalDetails!.id!});
        await this.updateCalendarShipArrivalAction();
      }

      this.$emit('closeDialog', true)
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    } finally {
      this.isCancellationSending = false;
    }
  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.SHIP_OWNER)
  }

  public get requiredCarSlots() {
    const carCount = this.shipArrivalDetails!.carsImportVolume! + this.shipArrivalDetails!.carsExportVolume!;
    return Math.ceil(carCount / process.env.VUE_APP_CAR_SLOT_VOLUME);
  }

  public get requiredHeavySlots() {
    const heavyCount = this.shipArrivalDetails!.heavyImportVolume! + this.shipArrivalDetails!.heavyExportVolume!;
    return Math.ceil(heavyCount / process.env.VUE_APP_HEAVY_SLOT_VOLUME);
  }

  public get heavyBookedSlots(): number {
    if (!this.shipArrivalDetails!.booking) {
      return 0;
    }
    const bookedSlots = (this.shipArrivalDetails!.booking as Booking).bookedSlots;
    return bookedSlots.filter(slot => slot.slotType === SlotType.HEAVY)
        .reduce((sum: number, slot: Slot) => sum + slot.amount!, 0);
  }

  public get carBookedSlots(): number {
    if (!this.shipArrivalDetails!.booking) {
      return 0;
    }
    const bookedSlots = (this.shipArrivalDetails!.booking as Booking).bookedSlots;
    return bookedSlots.filter(slot => slot.slotType === SlotType.CAR)
        .reduce((sum: number, slot: Slot) => sum + slot.amount!, 0);
  }
}

