import { render, staticRenderFns } from "./ShipArrivalDetails.component.vue?vue&type=template&id=7b28fb47&scoped=true&"
import script from "./ShipArrivalDetails.component.vue?vue&type=script&lang=ts&"
export * from "./ShipArrivalDetails.component.vue?vue&type=script&lang=ts&"
import style0 from "./ShipArrivalDetails.component.vue?vue&type=style&index=0&id=7b28fb47&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b28fb47",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VCombobox,VDivider,VHover,VIcon,VRow})
